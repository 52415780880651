import React from "react";
import { Link } from "react-router-dom";
import facebookIcon from "../../../assets/facebook.svg";
import twitterIcon from "../../../assets/twitter.svg";
import { Newsletter } from "../Newsletter";
import {
  ContactContainer,
  FooterContainer,
  IconsContainer,
  LegalContainer,
  QuestionsContainer,
  TextContainer,
  TextItem,
} from "./Footer.styled";

export const Footer = () => {
  return (
    <FooterContainer>
      <ContactContainer>
        <TextContainer>
          <TextItem order={2}>
            <h2>Questions?</h2>
            <QuestionsContainer>
              <p>If you have any questions please contact us at:</p>
              <a href="mailto: contact@supplychainstatus.org" type="email">
                contact@supplychainstatus.org
              </a>
            </QuestionsContainer>
          </TextItem>
          <TextItem order={3}>
            <h2>Find us:</h2>
            <IconsContainer>
              <a
                href="https://www.facebook.com/SupplyChainStatus/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebookIcon} alt="facebook" />
              </a>
              <a
                href=" https://twitter.com/SupplyChainStat"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitterIcon} alt="twitter" />
              </a>
            </IconsContainer>
          </TextItem>
          <TextItem order={1}>
            <h2>Subscribe to our newsletter</h2>
            <Newsletter />
          </TextItem>
        </TextContainer>
      </ContactContainer>
      <LegalContainer>
        <p>Copyright ©2022 Supply Chain Status</p>
        <p>
          <Link to="/terms-and-conditions">Terms and Conditions</Link> and{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </LegalContainer>
    </FooterContainer>
  );
};
