import React from "react";
import { StyledLink } from "../partials/Typography";
import { MainWrapper, PrimaryTitle, TextBox, TextWrapper } from "./Main.styled";

export const PageNotFound = () => {
  return (
    <MainWrapper>
      <TextWrapper>
        <TextBox>
          <PrimaryTitle>404</PrimaryTitle>
          <h1>Page Not Found!</h1>
          <p>We're sorry, but we can't find the page you were looking for.</p>
          <p>
            Try refreshing the page or click the button below to go back to the
            Homepage
          </p>
          <StyledLink to="/" variant="primary">
            Home
          </StyledLink>
        </TextBox>
      </TextWrapper>
    </MainWrapper>
  );
};
