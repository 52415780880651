export interface Show {
  show: boolean;
}
export enum Status {
  notBusy,
  average,
  veryBusy,
}
export interface Port {
  id: string;
  portId: string;
  name: string;
  location: string;
  status: Status;
  description: string;
}
export interface PortSettings {
  updatedAt: string;
}
export interface PortData {
  ports: Array<Port>;
  settings: PortSettings;
}

export interface RichTextContentType {
  value: string;
  type: "title" | "text";
}
export interface RichTextData {
  content: Array<RichTextContentType>;
  name: string;
  updatedAt: string;
}

export type ModalType = "signIn" | "signUp" | "info";
export type ProviderType = "facebook" | "google";

export interface SignUpForm {
  name: string;
  email: string;
  password: string;
  typeOfBusiness?: string;
  annualSales?: string;
}
export interface SignInForm {
  email: string;
  password: string;
  remember: boolean;
}

export interface SignUpErrors {
  name: string;
  email: string;
  password: string;
}
export interface SignInErrors {
  email: string;
  password: string;
}
