import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignInErrors, SignInForm } from "../../../models/interfaces";
import {
  doResetPassword,
  doSignIn,
  validateRequired,
} from "../../../utils/formHelpers";
import { Button } from "../Button";
import { Checkbox, FormInput, Label, PasswordInput } from "../Input";
import { ErrorMessage, SmallText } from "../Typography";
import { FlexWrapper, Form } from "./Modal.styled";

export const EmailSignIn = () => {
  const [form, setForm] = useState<SignInForm>(resetForm);
  const [errors, setErrors] = useState<SignInErrors>(errorMessages);

  const navigate = useNavigate();
  const validate = () => {
    const { email, password } = form;
    const checkErrors = { ...errorMessages };
    checkErrors.email = validateRequired(email);
    checkErrors.password = validateRequired(password);
    setErrors(checkErrors);
    return Object.values(checkErrors).every((val) => val.length === 0);
  };
  const onResetPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    doResetPassword(form);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      doSignIn(form, navigate);
    }
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };
  return (
    <Form onSubmit={onSubmit} noValidate>
      <Label>
        E-mail
        <FormInput
          placeholder="youremail@email.com"
          value={form.email}
          name="email"
          onChange={onChange}
          required
          error={!!errors.email}
          type="email"
        />
        <ErrorMessage>{errors.email}</ErrorMessage>
      </Label>
      <PasswordInput
        value={form.password}
        onChange={onChange}
        error={errors.password}
      />
      <FlexWrapper>
        <Label>
          <Checkbox
            type="checkbox"
            onChange={onCheckboxChange}
            name="remember"
            checked={form.remember}
          />
          <SmallText>Remember me</SmallText>
        </Label>
        <Button variant="transparent" onClick={onResetPassword}>
          <SmallText>
            <u>Forgot Password?</u>
          </SmallText>
        </Button>
      </FlexWrapper>

      <Button type="submit" variant="primary">
        LOG IN
      </Button>
    </Form>
  );
};

const resetForm: SignInForm = {
  email: "",
  password: "",
  remember: true,
};
const errorMessages: SignInErrors = {
  email: "",
  password: "",
};
