import React, { useEffect, useState } from "react";
import emailIcon from "../../../assets/email-white.svg";
import { useModal } from "../../../hooks/useModal";
import { SIGN_IN_TITLE, SIGN_IN_TO_CONTINUE } from "../../../utils/message";
import { Button } from "../Button";
import { EmailSignIn } from "./EmailSignIn";
import {
  ButtonsWrapper,
  ContentWrapper,
  ModalHeader,
  SmallTextWrapper,
} from "./Modal.styled";
import { ProviderAuthButtons } from "./ProviderAuthButtons";

export const SignInModal = () => {
  const [showEmailSignIn, setShowEmailSignIn] = useState(false);
  const { blocked, openModal } = useModal();

  useEffect(() => {
    return () => {
      setShowEmailSignIn(false);
    };
  }, []);

  return (
    <div>
      <ModalHeader>
        <h2>{blocked ? SIGN_IN_TO_CONTINUE : SIGN_IN_TITLE}</h2>
      </ModalHeader>
      <ContentWrapper>
        {showEmailSignIn ? (
          <EmailSignIn />
        ) : (
          <ButtonsWrapper>
            <ProviderAuthButtons />
            <Button
              withIcon
              variant="primary"
              onClick={() => setShowEmailSignIn(true)}
            >
              <img src={emailIcon} alt="email" />
              Log in with E-mail
            </Button>
          </ButtonsWrapper>
        )}
        <SmallTextWrapper>
          <small>
            Don't have an account?{" "}
            <Button variant="transparent" onClick={() => openModal("signUp")}>
              Sign Up for FREE
            </Button>
          </small>
        </SmallTextWrapper>
      </ContentWrapper>
    </div>
  );
};
