import { css, Global } from "@emotion/react";
import emotionReset from "emotion-reset";

export const colors = {
  text: "#033838",
  label: "rgba(3, 56, 56, 0.6)",
  primary: "#09B4D6",
  secondary: "#F2F5F5",
  white: "#FFFFFF",
  green: "#0DDAA9",
  yellow: "#FFC700",
  red: "#DD4B39",
  grayLighter: "#E8EDED",
  grayDarker: "#DDE4E4",
  border: "#e6ebeb",
  darkBlue: "#08425E",
};

export const breakpoints = {
  tablet: "(min-width: 800px)",
};

export const globalStyles = (
  <>
    <Global
      styles={css`
        ${emotionReset}

        div,
        main {
          box-sizing: border-box;
        }
        html,
        body {
          color: ${colors.text};
          font-family: GTWalsheimPro, sans-serif;
          font-size: 16px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        button,
        input {
          font-family: GTWalsheimPro, sans-serif;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }

        h1,
        strong {
          font-weight: 700;
        }
        h1 {
          font-size: 26px;
        }

        h2 {
          font-size: 24px;
          line-height: 32px;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
        }
        a {
          color: ${colors.text};
        }
      `}
    />
  </>
);
