import styled from "@emotion/styled";
import React, { ChangeEvent, FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { subscribeToNewsletter } from "../../utils/firebase";
import {
  GENERAL_ERROR,
  LOADING,
  NEWSLETTER_SUCCESS,
} from "../../utils/message";
import { Button } from "./Button";
import { Input } from "./Input";

export const Newsletter = () => {
  const [email, setEmail] = useState("");
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const doSubscribe = async () => {
      try {
        await toast.promise(subscribeToNewsletter(email), {
          loading: LOADING,
          success: NEWSLETTER_SUCCESS,
          error: GENERAL_ERROR,
        });
      } catch (e) {
        console.log(e);
      }
    };
    doSubscribe();
    setEmail("");
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  return (
    <InputContainer onSubmit={onSubmit}>
      <Input
        aria-label="email"
        type="email"
        required
        placeholder="Enter your email"
        value={email}
        onChange={onChange}
      />
      <Button variant="primary" type="submit">
        Subscribe
      </Button>
    </InputContainer>
  );
};

export const InputContainer = styled.form`
  display: flex;
  button {
    border-radius: 0 8px 8px 0;
  }
  input {
    border-radius: 8px 0 0 8px;
    max-width: 228px;
  }
`;
