import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../../hooks/useForm";
import { SignUpErrors, SignUpForm } from "../../../models/interfaces";
import {
  doSignUp,
  validateLength,
  validateRequired,
} from "../../../utils/formHelpers";
import { Button } from "../Button";
import { FormInput, Label, PasswordInput } from "../Input";
import { ErrorMessage, SmallText } from "../Typography";
import { Form } from "./Modal.styled";

export const EmailSignUp = () => {
  const [form, onChange] = useForm<SignUpForm>(resetForm);
  const [errors, setErrors] = useState<SignUpErrors>(errorMessages);
  const navigate = useNavigate();
  const validate = () => {
    const { name, email, password } = form;
    const checkErrors = { ...errorMessages };
    checkErrors.name = validateRequired(name);
    checkErrors.email = validateRequired(email);
    checkErrors.password = validateLength(8, password);
    setErrors(checkErrors);
    return Object.values(checkErrors).every((val) => val.length === 0);
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      doSignUp(form, navigate);
    }
  };

  return (
    <Form onSubmit={onSubmit} noValidate>
      <Label>
        Name
        <FormInput
          placeholder="Your name goes here"
          value={form.name}
          name="name"
          onChange={onChange}
          required
          error={!!errors.name}
          type="text"
        />
        <ErrorMessage>{errors.name}</ErrorMessage>
      </Label>
      <Label>
        E-mail
        <FormInput
          placeholder="youremail@email.com"
          value={form.email}
          name="email"
          onChange={onChange}
          required
          error={!!errors.email}
          type="email"
        />
        <ErrorMessage>{errors.email}</ErrorMessage>
      </Label>
      <PasswordInput
        onChange={onChange}
        value={form.password}
        error={errors.password}
      />
      <Label>
        Type of business <SmallText>(optional)</SmallText>
        <FormInput
          placeholder="example: trade, government ..."
          value={form.typeOfBusiness}
          name="typeOfBusiness"
          onChange={onChange}
          type="string"
        />
      </Label>
      <Label>
        Annual sales <SmallText>(optional)</SmallText>
        <FormInput
          placeholder="100000000$"
          value={form.annualSales}
          name="annualSales"
          onChange={onChange}
          min={0}
          type="number"
        />
      </Label>
      <Button type="submit" variant="primary">
        SIGN UP
      </Button>
    </Form>
  );
};

const resetForm: SignUpForm = {
  name: "",
  email: "",
  password: "",
  typeOfBusiness: "",
  annualSales: "",
};
const errorMessages: SignUpErrors = {
  name: "",
  email: "",
  password: "",
};
