import React, { useEffect, useState } from "react";
import emailIcon from "../../../assets/email-white.svg";
import { useModal } from "../../../hooks/useModal";
import { SIGN_UP_TITLE, SIGN_UP_TO_CONTINUE } from "../../../utils/message";
import { Button } from "../Button";
import { SmallText, StyledLink } from "../Typography";
import { EmailSignUp } from "./EmailSignUp";
import {
  ButtonsWrapper,
  ContentWrapper,
  ModalHeader,
  SmallTextWrapper,
} from "./Modal.styled";
import { ProviderAuthButtons } from "./ProviderAuthButtons";

export const SignUpModal = () => {
  const [showEmailSignUp, setShowEmailSignUp] = useState(false);
  const { blocked, openModal } = useModal();

  useEffect(() => {
    return () => {
      setShowEmailSignUp(false);
    };
  }, []);

  return (
    <div>
      <ModalHeader>
        <h2>{blocked ? SIGN_UP_TO_CONTINUE : SIGN_UP_TITLE}</h2>
      </ModalHeader>
      <ContentWrapper>
        {showEmailSignUp ? (
          <EmailSignUp />
        ) : (
          <ButtonsWrapper>
            <ProviderAuthButtons />
            <Button
              withIcon
              variant="primary"
              onClick={() => setShowEmailSignUp(true)}
            >
              <img src={emailIcon} alt="email" />
              Sign up with E-mail
            </Button>
          </ButtonsWrapper>
        )}
        <SmallTextWrapper>
          <SmallText>
            By continuing, you agree to the{" "}
            <StyledLink variant="secondary" to="/terms-and-conditions">
              Terms of Service
            </StyledLink>{" "}
            and{" "}
            <StyledLink variant="secondary" to="/privacy-policy">
              Privacy Policy
            </StyledLink>
          </SmallText>
          <small>
            Already have an account?{" "}
            <Button variant="transparent" onClick={() => openModal("signIn")}>
              Log in
            </Button>
          </small>
        </SmallTextWrapper>
      </ContentWrapper>
    </div>
  );
};
