import styled from "@emotion/styled";
import backgroundImg from "../../assets/background.png";
import { breakpoints, colors } from "../../style/global";

export const MainWrapper = styled.main`
  min-height: calc(100vh - 230px);
  margin-bottom: 16px;
  h1 {
    text-align: center;
    padding: 40px 0 24px;
  }
  @media ${breakpoints.tablet} {
    background: url(${backgroundImg}) no-repeat center 84px;
    h1 {
      font-size: 32px;
    }
  }
`;

export const ContentWrapper = styled.div`
  border-radius: 8px;
  max-width: 906px;
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  margin: 0 auto;
  background-color: ${colors.white};
  @media ${breakpoints.tablet} {
    padding: 24px;
    border: 1px solid ${colors.border};
  }
`;

export const DateText = styled.p`
  opacity: 0.6;
  text-align: end;
  font-size: 12px;
  padding-bottom: 16px;
  padding-right: 16px;
  text-transform: uppercase;
  @media ${breakpoints.tablet} {
    font-size: 14px;
  }
`;

export const TextWrapper = styled.div`
  margin: 0 auto;
  max-width: 640px;
  padding: 60px 0 30px;
  @media ${breakpoints.tablet} {
    padding-top: 100px;
  }
`;

export const TextBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h1 {
    padding: 16px 0;
  }
  a {
    color: ${colors.primary};
  }
  p {
    padding-bottom: 8px;
  }

  @media ${breakpoints.tablet} {
    padding: 64px 0;
    border: 1px solid ${colors.border};
  }
`;

export const PrimaryTitle = styled.h1`
  color: ${colors.primary};
`;
