export const GENERAL_ERROR = "There was a problem, please try again later.";
export const SIGN_IN_SUCCESS = "Successfully signed in!";
export const SIGN_UP_SUCCESS = "Please verify your e-mail!";
export const VERIFY_AGAIN_SUCCESS = "E-mail sent!";
export const INVALID_PASSWORD = "Password is invalid";
export const EMAIL_NOT_FOUND = "E-mail is not registered";
export const EMAIL_IN_USE = "E-mail is already in use";
export const SIGN_UP_TO_CONTINUE = "Please sign up to continue";
export const SIGN_IN_TO_CONTINUE = "Please log in to continue";
export const SIGN_IN_TITLE = "Log in to your account";
export const SIGN_UP_TITLE = "Sign up for free";
export const LOADING = "Loading...";
export const NEWSLETTER_SUCCESS = "Successfully subscribed to our newsletter!";
export const RESET_PASSWORD_SUCCESS =
  "Check your e-mail to reset your password";

export const REQUIRED_ERROR = "Please enter a required field";
export const EMAIL_ERROR = "Please enter a valid e-mail address";
export const PASSWORD_LENGTH_ERROR = "Please enter at least 8 characters";
