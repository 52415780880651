import React, { useEffect, useState } from "react";
import { RichTextData } from "../../../models/interfaces";
import {
  getTermsAndConditions,
  renderParagraphs,
} from "../../../utils/firebase";
import { ContentWrapper, DateText, MainWrapper } from "../Main.styled";
import { Section, TextWrapper, Title } from "./TermsOfService.styled";

export const TermsAndConditions = () => {
  const [data, setData] = useState<RichTextData>();

  useEffect(() => {
    const fetchText = async () => {
      try {
        const res = await getTermsAndConditions();
        setData(res[0]);
      } catch (e) {
        console.log(e);
      }
    };
    fetchText();
  }, []);

  return (
    <MainWrapper>
      <TextWrapper>
        <h1>Supply Chain Status</h1>
        <ContentWrapper>
          {data?.updatedAt && (
            <DateText>LAST UPDATED: {data?.updatedAt}</DateText>
          )}
          {data?.content.map((content, index) => (
            <div key={index}>
              {content.type === "title" ? (
                <Title>{content.value}</Title>
              ) : (
                <Section>
                  {renderParagraphs(content.value).map((paragraph, i) => (
                    <p key={i}>{paragraph}</p>
                  ))}
                </Section>
              )}
            </div>
          ))}
        </ContentWrapper>
      </TextWrapper>
    </MainWrapper>
  );
};
