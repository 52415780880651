import React from "react";
import infoIcon from "../../../assets/info.svg";
import { useModal } from "../../../hooks/useModal";
import { Button } from "../../partials/Button";
import { InfoWrapper } from "./Legend.styled";

export const InfoButton = () => {
  const { openModal } = useModal();

  return (
    <InfoWrapper>
      <Button variant="transparent" onClick={() => openModal("info")}>
        <img src={infoIcon} alt="red" />
      </Button>
    </InfoWrapper>
  );
};
