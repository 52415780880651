import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore/lite";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Port,
  PortData,
  ProviderType,
  RichTextData,
  SignInForm,
  SignUpForm,
} from "../models/interfaces";
import {
  EMAIL_IN_USE,
  EMAIL_NOT_FOUND,
  GENERAL_ERROR,
  INVALID_PASSWORD,
} from "./message";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app, "us-central1");

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const ports = collection(db, "ports");
const portSettings = collection(db, "port-settings");
const termsAndConditions = collection(db, "terms-and-conditions");
const privacyPolicy = collection(db, "privacy-policy");

// AUTHENTICATION
export const authState = async (setAuth: (state: boolean) => void) => {
  onAuthStateChanged(auth, (user) => {
    if (user?.emailVerified) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  });
};

export const sendConfirmationLink = async () => {
  await sendEmailVerification(auth.currentUser!);
};

export const subscribeToNewsletter = async (email: string) => {
  const saveToHubspot = httpsCallable(functions, "saveToHubspot");
  return await saveToHubspot(JSON.stringify({ email: email }));
};

const setUser = async (uid: string, form: SignUpForm) => {
  try {
    // save user to firestore
    await setDoc(doc(db, "users", uid), {
      name: form.name,
      email: form.email,
      annualSales: form.annualSales || null,
      typeOfBusiness: form.typeOfBusiness || "",
    });
    // save user to hubspot
    await subscribeToNewsletter(form.email);
  } catch (e) {
    console.log(e);
  }
};

export const signUp = async (form: SignUpForm) => {
  const res = await createUserWithEmailAndPassword(
    auth,
    form.email,
    form.password
  );
  if (res.user) {
    await setUser(res.user.uid, form);
    return await sendConfirmationLink();
  }
};

export const doSignOut = async () => {
  await signOut(auth);
};

export const signInWithEmail = async ({
  email,
  password,
  remember,
}: SignInForm) => {
  // necessary because firebase automatically signs in a user after registration! Force re-authentication to trigger onAuthStateChanged
  if (auth.currentUser) {
    auth.signOut();
  }
  if (remember) {
    await setPersistence(auth, browserLocalPersistence);
  } else {
    await setPersistence(auth, browserSessionPersistence);
  }
  return await signInWithEmailAndPassword(auth, email, password);
};

export const getSignInError = (error: string) => {
  switch (error) {
    case "auth/wrong-password":
      return INVALID_PASSWORD;
    case "auth/user-not-found":
      return EMAIL_NOT_FOUND;
    default:
      return GENERAL_ERROR;
  }
};
export const getSignUpError = (error: string) => {
  switch (error) {
    case "auth/email-already-in-use":
      return EMAIL_IN_USE;
    default:
      return GENERAL_ERROR;
  }
};

export const authWithProvider = async (provider: ProviderType) => {
  let res;
  switch (provider) {
    case "facebook":
      res = await signInWithPopup(auth, facebookProvider);
      break;
    case "google":
      res = await signInWithPopup(auth, googleProvider);
      break;
  }
  if (res.user) {
    const isNew = getAdditionalUserInfo(res);
    if (isNew) {
      setUser(res.user.uid, {
        email: res.user.email!,
        name: res.user.displayName!,
      } as SignUpForm);
    }
  }
};
export const resetPassword = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

// PORTS
export const getPorts = async () => {
  const snapshot = await getDocs(ports);
  const settingsSnapshot = await getDocs(portSettings);
  const settings = settingsSnapshot.docs.map((doc) => doc.data());
  const list = snapshot.docs.map((doc) => {
    const res = doc.data();
    res.id = doc.id;
    return res;
  });
  const updatedAt = renderDateAndTime(settings[0].updatedAt.seconds);
  const res: PortData = {
    ports: list as Array<Port>,
    settings: { updatedAt },
  };
  return res;
};

// TERMS OF SERVICE
export const getTermsAndConditions = async () => {
  const snapshot = await getDocs(termsAndConditions);
  const list = snapshot.docs.map((doc) => {
    const res = doc.data();
    res.updatedAt = renderDate(doc.data().updatedAt.seconds);
    return res;
  });
  return list as Array<RichTextData>;
};
export const getPrivacyPolicy = async () => {
  const snapshot = await getDocs(privacyPolicy);
  const list = snapshot.docs.map((doc) => {
    const res = doc.data();
    res.updatedAt = renderDate(doc.data().updatedAt.seconds);
    return res;
  });
  return list as Array<RichTextData>;
};
const renderDate = (seconds: number) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  const start = new Date(1970, 0, 1);
  start.setSeconds(seconds);
  return start.toLocaleDateString("en-US", options);
};
const renderDateAndTime = (seconds: number) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const start = new Date(1970, 0, 1);
  start.setSeconds(seconds);
  return start.toLocaleDateString("en-US", options);
};
export const renderParagraphs = (content: string) => {
  return content.split("\n");
};
