import React from "react";
import greenIcon from "../../../assets/green.svg";
import redIcon from "../../../assets/red.svg";
import yellowIcon from "../../../assets/yellow.svg";
import { InfoButton } from "./InfoButton";
import { Item, Wrapper } from "./Legend.styled";

export const Legend = () => {
  return (
    <Wrapper>
      <Item>
        <GreenCheck />
        <p>Not busy</p>
      </Item>
      <Item>
        <YellowCheck />
        <p>Average</p>
      </Item>
      <Item>
        <RedCheck />
        <p>Very busy</p>
      </Item>
      <InfoButton />
    </Wrapper>
  );
};

export const GreenCheck = () => <img src={greenIcon} alt="green" />;
export const YellowCheck = () => <img src={yellowIcon} alt="yellow" />;
export const RedCheck = () => <img src={redIcon} alt="red" />;
