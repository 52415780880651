import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Show } from "../../../models/interfaces";
import { breakpoints, colors } from "../../../style/global";
interface Up {
  up: boolean;
}
interface Even {
  even: boolean;
}
interface Description extends Show, Even {}

export const PortTable = styled.table`
  width: 100%;
`;

export const tableDataStyles = css`
  text-align: start;
  padding: 16px 8px 16px 0;
  :first-of-type {
    padding-left: 16px;
  }
  :last-of-type {
    padding-right: 16px;
    text-align: center;
  }
  @media ${breakpoints.tablet} {
    font-size: 16px;
  }
`;
export const ColumnTitle = styled.th`
  text-transform: uppercase;
  font-size: 14px;
  ${tableDataStyles}
`;
export const TableData = styled.td`
  font-size: 14px;
  line-height: 24px;

  ${tableDataStyles}
`;
export const PortName = styled(TableData)`
  color: ${colors.primary};
  text-transform: uppercase;
  cursor: pointer;
`;
export const DataRow = styled.tr<Even>`
  background-color: ${({ even }) => (even ? colors.secondary : colors.white)};
`;

const showCSS = css`
  visibility: visible;
  position: static;
  opacity: 1;
`;
const hideCSS = css`
  visibility: hidden;
  position: absolute;
  opacity: 0;
`;

export const PortDescription = styled.tr<Description>`
  transition: opacity 0.2s ease-in;
  ${({ show }) => (show ? showCSS : hideCSS)}
  background-color: ${({ even }) => (even ? colors.secondary : colors.white)};
  td {
    padding: 16px;
  }
  p {
    font-size: 14px;
    max-width: 750px;
    opacity: 0.8;
  }
  @media ${breakpoints.tablet} {
    p {
      font-size: 16px;
    }
  }
`;
export const ColumnRow = styled.tr`
  border-bottom: 1px solid #cdd7d7;
`;
export const Small = styled.small`
  font-size: 12px;
  text-transform: lowercase;
  opacity: 0.6;
  display: block;
  padding-top: 8px;
  @media ${breakpoints.tablet} {
    font-size: 14px;
    display: inline;
  }
`;
export const OrderStatus = styled.div<Up>`
  display: inline-flex;
  gap: 10px;
  cursor: pointer;
  img {
    ${({ up }) => up && "transform: rotate(180deg)"}
  }
`;
