import { createContext, useContext } from "react";
import { ModalType } from "../models/interfaces";

export interface ModalProps {
  blocked: boolean;
  setBlocked: (blocked: boolean) => void;
  closeModal: () => void;
  openModal: (modalType: ModalType) => void;
}
export const ModalContext = createContext<ModalProps>({
  blocked: false,
  setBlocked: () => {},
  closeModal: () => {},
  openModal: () => {},
});

export const useModal = () => useContext(ModalContext);
