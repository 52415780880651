import { ReactNode } from "react";
import { InfoModal } from "../components/partials/modals/InfoModal";
import { SignInModal } from "../components/partials/modals/SignInModal";
import { SignUpModal } from "../components/partials/modals/SignupModal";

export const getModalType = (modalType: string): ReactNode => {
  switch (modalType) {
    case "info":
      return <InfoModal />;
    case "signIn":
      return <SignInModal />;
    case "signUp":
    default:
      return <SignUpModal />;
  }
};
