import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContext } from "../hooks/useAuth";
import { ModalContext } from "../hooks/useModal";
import { ModalType } from "../models/interfaces";
import { authState } from "../utils/firebase";
import { Home } from "./dashboard/Home";
import { PageNotFound } from "./dashboard/PageNotFound";
import { PrivacyPolicy } from "./dashboard/terms-of-service/PrivacyPolicy";
import { TermsAndConditions } from "./dashboard/terms-of-service/TermsAndConditions";
import { VerifyEmail } from "./dashboard/VerifyEmail";
import { Footer } from "./partials/footer/Footer";
import { Modal } from "./partials/modals/Modal";
import { Navbar } from "./partials/Navbar";

const App = () => {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>("signUp");
  const [isAuth, setIsAuth] = useState(false);
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      await authState((state: boolean) => setIsAuth(state));
    };
    checkAuth();
  }, []);

  const openModal = (type: ModalType) => {
    setModalType(type);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <BrowserRouter>
      <AuthContext.Provider value={isAuth}>
        <ModalContext.Provider
          value={{ blocked, setBlocked, closeModal, openModal }}
        >
          <Toaster />
          <Navbar />
          <Routes>
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Modal isActive={open} type={modalType} />
          <Footer />
        </ModalContext.Provider>
      </AuthContext.Provider>
    </BrowserRouter>
  );
};

export default App;
