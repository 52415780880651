import styled from "@emotion/styled";
import React from "react";
import toast from "react-hot-toast";
import emailIcon from "../../assets/verify-email.svg";
import { colors } from "../../style/global";
import { sendConfirmationLink } from "../../utils/firebase";
import { GENERAL_ERROR, VERIFY_AGAIN_SUCCESS } from "../../utils/message";
import { Button } from "../partials/Button";
import { MainWrapper, TextBox, TextWrapper } from "./Main.styled";

export const VerifyEmail = () => {
  const onResend = () => {
    const doResend = async () => {
      try {
        await sendConfirmationLink();
        toast.success(VERIFY_AGAIN_SUCCESS);
      } catch (e) {
        toast.error(GENERAL_ERROR);
        console.log(e);
      }
    };
    doResend();
  };

  return (
    <MainWrapper>
      <TextWrapper>
        <TextBox>
          <img src={emailIcon} alt="email" />
          <h1>Verify Your E-mail</h1>
          <p>Please check your email for a link to verify your email address</p>
          <p>Once verified, you'll be able to continue.</p>
          <Small>
            Didn't receive an email?{" "}
            <Button variant="transparent" onClick={onResend}>
              Resend
            </Button>
          </Small>
        </TextBox>
      </TextWrapper>
    </MainWrapper>
  );
};

const Small = styled.small`
  padding-top: 24px;
  button {
    color: ${colors.primary};
  }
`;
