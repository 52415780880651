import styled from "@emotion/styled";
import { breakpoints, colors } from "../../../style/global";

export const TextWrapper = styled.div`
  padding: 0 16px;
  @media ${breakpoints.tablet} {
    padding: 0;
  }
`;
export const Title = styled.h5`
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.border};
  padding-bottom: 4px;
`;
export const Section = styled.section`
  padding: 8px 0 16px;
  opacity: 0.8;
  line-height: 24px;
  & > * {
    padding: 8px 0;
  }
  li {
    list-style: disc inside;
    padding-bottom: 8px;
  }
`;
