import React, { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useModal } from "../../hooks/useModal";
import { Legend } from "./legend/Legend";
import { MainWrapper } from "./Main.styled";
import { Table } from "./table/Table";

export const Home = () => {
  const isAuth = useAuth();
  const { blocked, setBlocked, closeModal, openModal } = useModal();

  useEffect(() => {
    if (!isAuth) {
      const current = Number(localStorage.getItem("access"));
      const next = current + 1;
      if (next <= 3) {
        localStorage.setItem("access", next.toString());
      }
      setBlocked(next >= 3);
    } else {
      localStorage.removeItem("access");
      setBlocked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    if (blocked) {
      openModal("signUp");
    } else {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocked, isAuth]);

  return (
    <MainWrapper>
      <h1>Supply Chain Status</h1>
      <Legend />
      <Table />
    </MainWrapper>
  );
};
