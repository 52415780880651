import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "../../style/global";

interface Props {
  variant?: "primary" | "secondary" | "tertiary" | "transparent";
  withIcon?: boolean;
}

const primaryStyles = css`
  background-color: ${colors.primary};
  color: ${colors.secondary};
  border: 1px solid ${colors.primary};
`;
const secondaryStyles = css`
  background-color: ${colors.secondary};
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
`;
const tertiaryStyles = css`
  opacity: 0.6;
  background-color: ${colors.secondary};
  color: ${colors.text};
  border: 1px solid ${colors.text};
`;
const transparentStyles = css`
  background-color: transparent;
  padding: 0;
`;

export const Button = styled.button<Props>`
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${({ variant }) =>
    css`
      ${variant === "primary" && primaryStyles}
      ${variant === "secondary" && secondaryStyles}
      ${variant === "tertiary" && tertiaryStyles}
      ${variant === "transparent" && transparentStyles}
    `}
  :disabled {
    opacity: 0.3;
  }

  @media ${breakpoints.tablet} {
    font-size: 18px;
  }
`;
