import React, { ReactNode, useEffect, useState } from "react";
import arrowIcon from "../../../assets/arrow.svg";
import { Port } from "../../../models/interfaces";
import { getPorts } from "../../../utils/firebase";
import { ContentWrapper, DateText } from "../Main.styled";
import {
  ColumnRow,
  ColumnTitle,
  OrderStatus,
  PortTable,
  Small,
} from "./Table.styled";
import { TableRow } from "./TableRow";

interface Columns {
  component: ReactNode;
  onClick?: () => void;
}

export const Table = () => {
  const [ports, setPorts] = useState<Array<Port>>([]);
  const [date, setDate] = useState("");
  const [desc, setDesc] = useState<boolean>(false);

  useEffect(() => {
    const fetchPorts = async () => {
      try {
        const res = await getPorts();
        const portData = res.ports.filter(({ status }) => status !== undefined);
        setPorts(portData);
        setDate(res.settings.updatedAt);
      } catch (e) {
        console.log(e);
      }
    };
    fetchPorts();
  }, []);

  const onSortClick = () => {
    const sorted = [...ports].sort((current, previous) =>
      desc ? previous.status - current.status : current.status - previous.status
    );
    setPorts(sorted);
    setDesc(!desc);
  };

  const tableColumns: Array<Columns> = [
    {
      component: (
        <>
          PORT <Small>({ports.length})</Small>
        </>
      ),
    },
    { component: <>LOCATION</> },
    {
      component: (
        <OrderStatus up={desc}>
          STATUS <img src={arrowIcon} alt="arrow" />
        </OrderStatus>
      ),
      onClick: onSortClick,
    },
  ];
  return (
    <ContentWrapper>
      <DateText>{date}</DateText>
      <PortTable>
        <thead>
          <ColumnRow>
            {tableColumns.map(({ component, onClick }, index) => (
              <ColumnTitle key={index} onClick={onClick}>
                {component}
              </ColumnTitle>
            ))}
          </ColumnRow>
        </thead>
        <tbody>
          {ports.map((port, index) => (
            <TableRow {...port} key={port.id} even={index % 2 !== 0} />
          ))}
        </tbody>
      </PortTable>
    </ContentWrapper>
  );
};
