import React from "react";
import toast from "react-hot-toast";
import fbIcon from "../../../assets/fb-white.svg";
import googleIcon from "../../../assets/google-white.svg";
import lineSvg from "../../../assets/line.svg";
import { ProviderType } from "../../../models/interfaces";
import { authWithProvider } from "../../../utils/firebase";
import { GENERAL_ERROR, SIGN_IN_SUCCESS } from "../../../utils/message";
import { FbButton, GoogleButton, Separator } from "./Modal.styled";

export const ProviderAuthButtons = () => {
  const onAuthorize = (provider: ProviderType) => {
    const authorize = async () => {
      try {
        await authWithProvider(provider);
        toast.success(SIGN_IN_SUCCESS);
      } catch (e) {
        console.log(e);
        toast.error(GENERAL_ERROR);
      }
    };
    authorize();
  };
  return (
    <>
      <FbButton withIcon onClick={() => onAuthorize("facebook")}>
        <img src={fbIcon} alt="facebook" />
        Continue with Facebook
      </FbButton>
      <GoogleButton withIcon onClick={() => onAuthorize("google")}>
        <img src={googleIcon} alt="google" />
        Continue with Google
      </GoogleButton>
      <Separator>
        <img src={lineSvg} alt="separator" />
        <small>Or</small>
        <img src={lineSvg} alt="separator" />
      </Separator>
    </>
  );
};
