import styled from "@emotion/styled";
import { Show } from "../../../models/interfaces";
import { breakpoints, colors } from "../../../style/global";
import { Button } from "../Button";

export const Wrapper = styled.div`
  max-width: 468px;
  height: max-content;
  max-height: calc(100vh - 84px);
  background-color: ${colors.white};
  border-radius: 8px;
  margin: 0 auto;
  position: fixed;
  top: 16px;
  bottom: 16px;
  right: 16px;
  left: 16px;
  overflow-y: auto;
  @media ${breakpoints.tablet} {
    top: 80px;
    max-height: calc(100vh - 124px);
  }
`;
export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 137, 173, 0.8);
  z-index: 200;
`;
export const ModalHeader = styled.div`
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  padding: 16px;
  text-align: center;
  border-radius: 8px 8px 0 0;
  text-transform: uppercase;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${breakpoints.tablet} {
    gap: 24px;
  }
`;

export const CloseButton = styled(Button)<Show>`
  background-color: transparent;
  position: absolute;
  padding: 0;
  top: 16px;
  right: 16px;
  display: ${({ show }) => (show ? "block" : "none")};
`;
export const FbButton = styled(Button)`
  background-color: #3c66c4;
  padding: 10px 16px;

  color: ${colors.white};
`;
export const GoogleButton = styled(Button)`
  padding: 10px 16px;
  background-color: #cf4332;
  color: ${colors.white};
`;

export const ContentWrapper = styled.div`
  padding: 32px 24px 40px;
  @media ${breakpoints.tablet} {
    padding-left: 64px;
    padding-right: 64px;
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  button {
    display: flex;
    gap: 8px;
    justify-content: center;
    width: 100%;
  }
`;
export const Separator = styled.div`
  font-size: 14px;
  opacity: 0.8;
  display: flex;
  gap: 20px;
  justify-content: center;
  img {
    width: 100%;
  }
`;
export const SmallTextWrapper = styled.div`
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  button {
    font-size: 14px;
    color: ${colors.primary};
  }
`;
export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoContentWrapper = styled.div`
  padding: 16px;
  p {
    padding-bottom: 8px;
    font-size: 14px;
  }
  div:first-of-type {
    padding-bottom: 4px;
  }
  @media ${breakpoints.tablet} {
    div:first-of-type {
      padding-bottom: 16px;
    }
    p {
      padding-bottom: 8px;
      font-size: 16px;
    }
    padding: 24px;
  }
`;
