import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import logoImg from "../../assets/logo.svg";
import { useAuth } from "../../hooks/useAuth";
import { useModal } from "../../hooks/useModal";
import { breakpoints, colors } from "../../style/global";
import { doSignOut } from "../../utils/firebase";
import { Button } from "./Button";

export const Navbar = () => {
  const isAuth = useAuth();
  const { openModal } = useModal();

  const onSignOut = async () => {
    try {
      await doSignOut();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Wrapper>
      <LogoContainer>
        <Link to="/">
          <img src={logoImg} alt="logo" />
        </Link>
      </LogoContainer>
      <ButtonContainer>
        {isAuth ? (
          <Button variant="tertiary" onClick={onSignOut}>
            Log out
          </Button>
        ) : (
          <>
            <Button variant="primary" onClick={() => openModal("signUp")}>
              Sign up<Desktop> for free</Desktop>
            </Button>
            <Button variant="secondary" onClick={() => openModal("signIn")}>
              Log in
            </Button>
          </>
        )}
      </ButtonContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${colors.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  @media ${breakpoints.tablet} {
    padding: 8px 32px;
  }
`;
const LogoContainer = styled.div`
  max-width: 65px;
  img {
    width: 100%;
  }
  @media ${breakpoints.tablet} {
    max-width: 108px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;
  height: 0%;
`;
const Desktop = styled.span`
  display: none;
  @media ${breakpoints.tablet} {
    display: inline;
  }
`;
