import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { colors } from "../../style/global";

interface Props {
  variant?: "primary" | "secondary" | "tertiary";
}

const primaryStyles = css`
  color: ${colors.primary};
  text-decoration: none;
`;
const secondaryStyles = css`
  color: ${colors.text};
`;
const tertiaryStyles = css``;

export const StyledLink = styled(Link)<Props>`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  ${({ variant }) =>
    css`
      ${variant === "primary" && primaryStyles}
      ${variant === "secondary" && secondaryStyles}
      ${variant === "tertiary" && tertiaryStyles}
    `}
`;

export const SmallText = styled.small`
  color: ${colors.text};
  opacity: 0.6;
  font-size: 14px;
  line-height: 24px;
`;
export const ErrorMessage = styled.p`
  color: ${colors.red};
  font-size: 14px;
  line-height: 24px;
`;
export const SuccessMessage = styled.p`
  color: ${colors.green};
  font-size: 14px;
  line-height: 16px;
`;
