import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import closeIcon from "../../../assets/close.svg";
import { useModal } from "../../../hooks/useModal";
import { ModalType } from "../../../models/interfaces";
import { getModalType } from "../../../utils/modalHelpers";
import { Backdrop, CloseButton, Wrapper } from "./Modal.styled";
export interface ModalProps {
  isActive?: boolean;
  type: ModalType;
}

export const Modal = ({ isActive = false, type }: ModalProps) => {
  const { blocked, setBlocked, closeModal } = useModal();
  const { pathname } = useLocation();
  // disable body scroll
  useEffect(() => {
    document.body.style.overflow = isActive ? "hidden" : "auto";
  }, [isActive]);

  // close modal when rerouting
  useEffect(() => {
    if (isActive && !blocked) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, blocked]);

  useEffect(() => {
    if (pathname !== "/") {
      setBlocked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return isActive ? (
    <Backdrop>
      <Wrapper>
        <CloseButton
          onClick={closeModal}
          show={type === "info" ? true : !blocked}
        >
          <img src={closeIcon} alt="close" />
        </CloseButton>
        {getModalType(type)}
      </Wrapper>
    </Backdrop>
  ) : null;
};
