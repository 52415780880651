import styled from "@emotion/styled";
import { breakpoints, colors } from "../../../style/global";

interface Order {
  order: number;
}

export const FooterContainer = styled.footer`
  h2,
  p {
    opacity: 0.6;
  }
`;
export const ContactContainer = styled.div`
  background-color: ${colors.grayLighter};
`;
export const IconsContainer = styled.div`
  display: flex;
  gap: 44px;
`;
export const QuestionsContainer = styled.div`
  max-width: 226px;
  p {
    padding-bottom: 8px;
  }
  a {
    color: ${colors.primary};
  }
`;
export const TextContainer = styled.section`
  max-width: 906px;
  padding: 32px 16px;
  margin: 0 auto;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  order: 3 1 2;
  @media ${breakpoints.tablet} {
    flex-direction: row;
    align-items: flex-start;
    text-align: start;
    padding: 32px;
  }
`;
export const TextItem = styled.article<Order>`
  order: ${({ order }) => order};
  h2 {
    padding-bottom: 24px;
  }
  @media ${breakpoints.tablet} {
    order: unset;
  }
`;
export const LegalContainer = styled.section`
  background-color: ${colors.grayDarker};
  text-align: center;
  padding: 16px 0 24px;
`;
