import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";
import { SignInForm, SignUpForm } from "../models/interfaces";
import {
  getSignInError,
  getSignUpError,
  resetPassword,
  signInWithEmail,
  signUp,
} from "./firebase";
import {
  LOADING,
  PASSWORD_LENGTH_ERROR,
  REQUIRED_ERROR,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
} from "./message";

export const validateRequired = (value?: string) =>
  !!value ? "" : REQUIRED_ERROR;
export const validateLength = (min: number, value?: string) =>
  value && value.length >= min ? "" : PASSWORD_LENGTH_ERROR;

export const doSignUp = async (
  form: SignUpForm,
  navigate: NavigateFunction
) => {
  try {
    await toast.promise(signUp(form), {
      loading: LOADING,
      success: SIGN_UP_SUCCESS,
      error: (err) => getSignUpError(err.code),
    });
    navigate("/verify-email");
  } catch (e) {
    console.log(e);
  }
};
export const doSignIn = async (
  form: SignInForm,
  navigate: NavigateFunction
) => {
  try {
    await toast.promise(signInWithEmail(form), {
      loading: LOADING,
      success: SIGN_IN_SUCCESS,
      error: (err) => getSignInError(err.code),
    });
    navigate("/");
  } catch (e) {
    console.log(e);
  }
};
export const doResetPassword = async (form: SignInForm) => {
  try {
    await resetPassword(form.email);
    toast.success(RESET_PASSWORD_SUCCESS);
  } catch (e) {
    console.log(e);
  }
};
