import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ChangeEvent, useState } from "react";
import passwordIcon from "../../assets/see-password.svg";
import { breakpoints, colors } from "../../style/global";
import { Button } from "./Button";
import { ErrorMessage } from "./Typography";

export const Input = styled.input`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid ${colors.white};
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  ::placeholder {
    color: #cdd7d7;
  }
  :focus {
    background-color: rgba(9, 180, 214, 0.05);
    border: 1px solid ${colors.primary};
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

interface State {
  error?: boolean;
}

export const FormInput = styled(Input)<State>`
  border: 1px solid ${colors.border};
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 8px;

  ${({ error }) =>
    error &&
    css`
      background-color: rgba(221, 75, 57, 0.05);
      border: 1px solid ${colors.red};
    `}
  @media ${breakpoints.tablet} {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export const Label = styled.label``;

export const ToggleVisibility = styled.div`
  position: absolute;
  right: 16px;
  top: 22px;
`;
export const PasswordWrapper = styled.div`
  position: relative;
  height: min-content;
`;

interface PasswordInputProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  error: string;
}

export const PasswordInput = ({
  onChange,
  value,
  error,
}: PasswordInputProps) => {
  const [inputType, setInputType] = useState<"password" | "text">("password");

  return (
    <Label>
      Password
      <PasswordWrapper>
        <FormInput
          placeholder="********"
          value={value}
          name="password"
          onChange={onChange}
          required
          minLength={8}
          error={!!error}
          type={inputType}
        />
        <ToggleVisibility>
          <Button
            variant="transparent"
            onClick={(e) => {
              e.preventDefault();
              setInputType(inputType === "password" ? "text" : "password");
            }}
          >
            <img src={passwordIcon} alt="see-password" />
          </Button>
        </ToggleVisibility>
      </PasswordWrapper>
      <ErrorMessage>{error}</ErrorMessage>
    </Label>
  );
};

export const Checkbox = styled.input`
  margin-right: 8px;
`;
