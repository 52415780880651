import React from "react";
import { InfoContentWrapper, ModalHeader } from "./Modal.styled";

export const InfoModal = () => {
  return (
    <div>
      <ModalHeader>
        <h2>DATA INFO</h2>
      </ModalHeader>
      <InfoContentWrapper>
        <div>
          <p>
            The Supply Chain Status dashboard provides up-to-date port
            congestion information for the largest ports in the United States.
          </p>
          <p>
            We base our analysis on real-time cargo tracking and live updates
            via advanced track and trace technology and data analytics.
          </p>
        </div>
        <div>
          <p>
            <strong>Not busy</strong>: There is little to no traffic in the
            port. Cargo is moving in and out of the port freely.
          </p>
          <p>
            <strong>Average</strong>: There is a normal amount of traffic in the
            port. There are no unusual signs and delays are not expected.
          </p>
          <p>
            <strong>Very busy</strong>: Traffic is heavy in the port. This may
            cause shipping delays.
          </p>
        </div>
      </InfoContentWrapper>
    </div>
  );
};
