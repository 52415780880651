import styled from "@emotion/styled";
import { breakpoints } from "../../../style/global";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 16px;
  gap: 16px;
  @media ${breakpoints.tablet} {
    padding: 12px 0 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  gap: 6px;
  p {
    opacity: 0.6;
    font-size: 14px;
  }
  img {
    max-width: 14px;
  }
`;

export const InfoWrapper = styled.div`
  align-self: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    max-width: 16px;
  }
`;
