import React, { useState } from "react";
import { Port, Status } from "../../../models/interfaces";
import { GreenCheck, RedCheck, YellowCheck } from "../legend/Legend";
import { DataRow, PortDescription, PortName, TableData } from "./Table.styled";

interface Props extends Port {
  even: boolean;
}

export const TableRow = ({
  id,
  name,
  location,
  status,
  description,
  even,
}: Props) => {
  const [show, setShow] = useState(false);
  const onPortToggle = () => {
    setShow(!show);
  };
  return (
    <>
      <DataRow key={id} even={even}>
        <PortName onClick={onPortToggle}>{name}</PortName>
        <TableData>{location}</TableData>
        <TableData>{getStatus(status)}</TableData>
      </DataRow>
      <PortDescription onClick={onPortToggle} show={show} even={even}>
        <td colSpan={4}>
          <p>{description}</p>
        </td>
      </PortDescription>
    </>
  );
};

const getStatus = (status: Status) => {
  switch (status) {
    case Status.average:
      return <YellowCheck />;
    case Status.veryBusy:
      return <RedCheck />;
    case Status.notBusy:
      return <GreenCheck />;
    default:
      <></>;
  }
};
